<script>
import Layout from "../_layouts/main";
import PageHeader from "@/components/page-header";

import Profile from "@/components/dashboard/profile"
//import Earning from "@/components/dashboard/earning"
import Stat from "@/components/dashboard/stat"
import Transaction from "@/components/dashboard/transaction"
//import Settlement from "@/components/dashboard/settlement"
import Board from '@/components/dashboard/board'

/**
 * Dashboard Component
 */
export default {
  components: {
    Layout,
    PageHeader,
    Profile,
    Stat,
    Transaction,
    //Earning,
    //Settlement,
    Board
  },
  data() {
    return {
      title: "전체현황",
      showModal: false, // subscribe modal
      fullPage: true,
      canCancel: false,
      useSlot: false,
      loader: "spinner",
      color: "#007bff",
      bgColor: "#ffffff",
      height: 128,
      width: 128,
      timeout: 3000, //ms
      fetchingStats: false,
      earningStatus: true,
    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <div class="row">
      <div class="col-xl-12">
        <Profile />
      </div>
      <!-- end col -->
      <div class="col-xl-12">
        <div class="row">
          <div class="col-md-4">
            <Stat id="stat0" :title="$t('전일통계')" :type="'yesterday'" ref="stat0" />
          </div>
          <div class="col-md-4">
            <Stat id="stat1" :title="$t('금일통계')" :type="'today'" ref="stat1" />
          </div>
          <div class="col-md-4">
            <Stat id="stat2" :title="$t('당월통계')" :type="'month'" ref="stat2" />
          </div>
        </div>
        <!--<Settlement />-->
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('공지사항') }} <small class="float-end"><router-link to="/setting/notice">{{ $t('더보기') }}</router-link></small></h4>
            <Board :boardKey="'notice'" />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('업데이트') }} <small class="float-end"><router-link to="/setting/update">{{ $t('더보기') }}</router-link></small></h4>
            <Board :boardKey="'update'" />
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('게임사 업데이트') }} <small class="float-end"><router-link to="/setting/vendor/update">{{ $t('더보기') }}</router-link></small></h4>
            <Board :boardKey="'vendor_update'" />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->

    <div class="row" style="display: none;">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('최근 거래내역') }}</h4>
            <!-- Transactions table -->
            <Transaction />
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
