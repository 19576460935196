<script>

export default {
  data() {
    return {
      working: true,
      preloaderFlag: null,
      validBet: 0,
      bet: 0,
      win: 0,
      draw: 0,
      cancel: 0,
      winlose: 0,
      date: ''
    }
  },
  components: {

  },
  props: {
    id: {
      type: String,
      default: 'stat'
    },
    title: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    type: {
      type: String
    }
  },
  methods: {
    getStatBetToday: async function () {
      try {
        let t = new Date()

        let beginDate
        let endDate
        if (this.type === 'yesterday') {
          beginDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
          beginDate.setDate(beginDate.getDate() - 1)
          endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
          endDate.setDate(endDate.getDate())

          this.date = beginDate.getFullYear().toString() + '-' + (beginDate.getMonth() + 1).toString() + '-' + beginDate.getDate().zf(2)
        } else if (this.type === 'today') {
          beginDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
          endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
          endDate.setDate(endDate.getDate() + 1)

          this.date = beginDate.getFullYear().toString() + '-' + (beginDate.getMonth() + 1).toString() + '-' + beginDate.getDate().zf(2)
        } else if (this.type === 'month') {
          beginDate = new Date(t.formatUTC('YYYY-MM-01 00:00:00'))
          endDate = new Date(t.formatUTC('YYYY-MM-DD 00:00:00'))
          endDate.setDate(endDate.getDate() + 1)

          this.date = beginDate.getFullYear().toString() + '-' + (beginDate.getMonth() + 1).toString()
        }

        beginDate = beginDate.toISOString()
        endDate = endDate.toISOString()

        const result = await this.$API.statistic.getStatBetDate(beginDate, endDate)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        const betSum = result.document.betSum.floatVal(0)
        const winSum = result.document.winSum.floatVal(0)
        const drawSum = result.document.drawSum.floatVal(0)
        const cancelSum = result.document.cancelSum.floatVal(0)

        this.bet = betSum
        this.win = winSum
        this.draw = drawSum
        this.cancel = cancelSum

        // 유효 배팅금
        this.validBet = betSum - drawSum - cancelSum
        // this.win = winSum - this.validBet
        this.win = winSum
        this.winlose = (this.validBet - this.win)
      } catch(e) {
        this.$log.error(e)
      }
    }
  },
  mounted() {
    this.getStatBetToday()
  }
};
</script>

<template>
  <div ref="contentWrapper">
    <div class="card mini-stats-wid">
      <div class="card-body">
        <div class="d-flex">
          <div class="flex-grow-1">
            <h4 class="text-muted fw-medium mb-2"><i :class="icon"></i> {{ title }}</h4>
            <hr />
            <p class="mb-0">배팅 <span class="badge bg-primary float-end">{{ bet.formatThousands() }}</span></p>
            <p class="mb-0">승 <span class="badge bg-danger float-end">{{ win.formatThousands() }}</span></p>
            <p class="mb-0">무 <span class="badge bg-secondary float-end">{{ draw.formatThousands() }}</span></p>
            <p class="mb-0">취소 <span class="badge bg-dark float-end">{{ cancel.formatThousands() }}</span></p>
            <p class="mb-0">수익금 <span class="badge bg-success float-end">{{ winlose.formatThousands() }}</span></p>
          </div>
        </div>
      </div>
      <!-- end card-body -->
    </div>
  </div>
</template>
