<script>
import Read from '../board/read'
export default {
  components: {
    Read
  },
  data() {
    return {
      list: []
    }
  },
  props: {
    boardKey: {
      type: String,
    },
  },
  mounted() {
    this.searchList().then()
  },
  methods: {
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const params = {
          search: {},
          sdate: null,
          edate: null,
          sort: {createdAt: -1},
          limit: 5,
          page: 1
        }

        const result = await this.$API.board.list(this.boardKey, params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        let totalCount = result.totalCount - ((result.page-1) * result.limit)
        for(const key in result.documents) {
          const document = result.documents[key]
          document.number = totalCount
          totalCount--
        }

        this.list = result.documents
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    openRead: async function(document) {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.board.read(this.boardKey, document._id)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.$refs['board-document-read'].open(result.document)
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
  <div ref="content-wrapper">
    <div class="table-responsive mb-0">
      <table class="table align-middle table-nowrap">
        <colgroup>
          <col style="width: 80px;"/>
          <col />
          <col style="width: 150px;"/>
        </colgroup>
        <thead class="table-dark">
        <tr>
          <th class="align-middle">#</th>
          <th class="align-middle">제목</th>
          <th class="align-middle">일자</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="document in list" :key="document.id">
          <td v-html="document.number.formatThousands()"></td>
          <td class="text-start"><a href="javascript:void(0);" @click="openRead(document)" v-html="document.title"></a></td>
          <td v-html="new Date(document.createdAt).format('YYYY-MM-DD')"></td>
        </tr>
        </tbody>
      </table>
    </div>
    <Read ref="board-document-read" @searchList="searchList" />
  </div>
</template>
