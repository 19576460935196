<script>

export default {
  components: {

  },
  data() {
    return {
      working: true,
      userType: this.$store.getters['auth/user'].type,
      totalUserCash: 0,
      totalPartnerCash: 0
    };
  },
  methods: {
    getUserCashSum: async function() {
      let result = await this.$API.user.getChildCashSum()
      if (result.code !== 0) {
        return alert(result.msg)
      }

      this.totalUserCash = result.document.userCashSum
      this.totalPartnerCash = result.document.partnerCashSum
    }
  },
  mounted() {
    const self = this
    setTimeout(() => {
      const preloader = document.getElementById("profileLoader");
      preloader.style.display = "none";

      const status = document.getElementById("profileLoaderStatus");
      status.style.display = "none";

      self.working = false
    }, 100)

    this.getUserCashSum().then()
  },
  computed: {

  }
};
</script>
<template>
  <div :class="{ 'is-loading': working }">
    <div class="preloader-component" id="profileLoader">
      <div class="status" id="profileLoaderStatus">
        <div class="spinner-chase">
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
          <div class="chase-dot"></div>
        </div>
      </div>
    </div>
      <div class="card-body">
        <div class="row">
          <div class="col-lg-3">
            <div class="card" style="background-color: #222222;">
              <div class="card-body">
                <h4 class="card-title mb-3" style="color: #ffffff;">{{ $t('보유 캐쉬' ) }}</h4>
                <p class="text-muted mb-0">{{ $store.getters['auth/user'].cash.floatVal(2).formatThousands() }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card" style="background-color: #222222;">
              <div class="card-body">
                <h4 class="card-title mb-3" style="color: #ffffff;">{{ $t('회원 캐쉬' ) }}</h4>
                <p class="text-muted mb-0">{{ totalUserCash.floatVal(2).formatThousands() }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card" style="background-color: #222222;">
              <div class="card-body">
                <h4 class="card-title mb-3" style="color: #ffffff;">{{ $t('에이전트 캐쉬' ) }}</h4>
                <p class="text-muted mb-0">{{ totalPartnerCash.floatVal(2).formatThousands() }}</p>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="card" style="background-color: #222222;">
              <div class="card-body">
                <h4 class="card-title mb-3" style="color: #ffffff;">{{ $t('캐쉬 합계' ) }} <small class="float-end">회원+에이전트</small></h4>
                <p class="text-muted mb-0">{{ (totalUserCash + totalPartnerCash).floatVal(2).formatThousands() }}</p>
              </div>
            </div>
          </div>

      <!-- end card-body -->
        </div>
      </div>
    <!-- end card -->
  </div>
</template>
