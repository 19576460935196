<script>

/**
 * Transactions component
 */
export default {
  components: {
  },
  props: {

  },
  data() {
    return {
      list: []
    };
  },
  mounted() {
    this.searchList().then()
  },
  methods: {
    searchList: async function() {
      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        localStorage.setItem('limit', this.limit)

        const params = {
          search: {},
          sdate: null,
          edate: null,
          sort: {createdAt: -1},
          limit: 20,
          page: 1
        }

        const result = await this.$API.transaction.list(params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.list = []
        let totalCount = result.totalCount - ((result.page-1) * result.limit)
        for(const key in result.documents) {
          const document = result.documents[key]
          document.number = totalCount
          totalCount--
        }

        this.list = result.documents
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
  <div ref="contentWrapper">
    <div class="table-responsive mb-0">
      <table class="table align-middle table-sm table-hover table-nowrap sticky-table table-striped-columns">
        <thead class="table-dark">
        <tr>
          <th>{{ $t('회원') }}{{ $t('정보') }}</th>
          <th>{{ $t('상위') }}{{ $t('정보') }}</th>
          <th>{{ $t('거래형식') }}</th>
          <th>{{ $t('거래방식') }}</th>
          <th>{{ $t('대상') }}</th>
          <th>{{ $t('게임사') }}</th>
          <th>{{ $t('게임종류') }}</th>
          <th>{{ $t('게임형식') }}</th>
          <th>{{ $t('게임명') }}</th>
          <th>{{ $t('이전') + ' ' + $t('보유캐쉬') }}</th>
          <th>{{ $t('금액') }}</th>
          <th>{{ $t('이후') + ' ' + $t('보유캐쉬') }}</th>
          <th>{{ $t('시간') }}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="record in list" :key="record.id">
          <!-- 회원정보 //-->
          <td class="text-start">
            <template v-if="record.user">
              <span class="badge badge-pill badge-soft-pink" v-if="record.user.type === 'admin'">관리자</span>
              <span class="badge badge-pill badge-soft-primary" v-if="record.user.type === 'partner' && record.user.level === 'top'">본사</span>
              <span class="badge badge-pill badge-soft-success" v-if="record.user.type === 'partner' && record.user.level === 'super'">부본사</span>
              <span class="badge badge-pill badge-soft-info" v-if="record.user.type === 'partner' && record.user.level === 'master'">지사</span>
              <span class="badge badge-pill badge-soft-warning" v-if="record.user.type === 'partner' && record.user.level === 'agent'">총판</span>
              <span class="badge badge-pill badge-soft-dark" v-if="record.user.type === 'partner' && record.user.level === 'shop'">매장</span>
              <span class="badge badge-pill badge-soft-secondary" v-if="record.user.type === 'user'">회원</span>
              <span class="ms-1"><router-link :to="'/user/detail/' + record.user.id" target="_blank">{{ record.username }}</router-link></span>
              <!--
              <br />
              <span v-if="record.groupKey">{{ record.groupKey }} - </span> {{ record.siteUsername }}
              //-->
            </template>
          </td>
          <!-- 상위정보 //-->
          <td class="text-start">
            <template v-if="record.parent">
              <span class="badge badge-pill badge-soft-primary" v-if="record.parent.level === 'top'">본사</span>
              <span class="badge badge-pill badge-soft-success" v-if="record.parent.level === 'super'">부본사</span>
              <span class="badge badge-pill badge-soft-info" v-if="record.parent.level === 'master'">지사</span>
              <span class="badge badge-pill badge-soft-warning" v-if="record.parent.level === 'agent'">총판</span>
              <span class="badge badge-pill badge-soft-dark" v-if="record.parent.level === 'shop'">매장</span>
              <!--
              <span class="badge  p-1" :class="{'bg-primary': record.parent.useSeamless === true, 'bg-success': record.parent.useSeamless === false}" v-b-tooltip.hover data-placement="top" :title="record.parent.useSeamless === true ? '심리스' : '트랜스퍼'" v-html="record.parent.useSeamless === true ? 'S' : 'T'"></span>
              //-->
              <span class="ms-1"><router-link :to="'/partner/detail/' + record.parent.id" target="_blank">{{ record.parent.username }}</router-link></span>
            </template>
          </td>
          <!-- 거래 형식 //-->
          <td>
            <span class="badge badge-pill badge-soft-primary" v-if="record.category === 'cash'"><i class="fa fa-piggy-bank"></i> 캐쉬변동</span>
            <span class="badge badge-pill badge-soft-success" v-if="record.category === 'money'"><i class="fa fa-piggy-bank"></i> 캐쉬변동</span>
            <span class="badge badge-pill badge-soft-success" v-if="record.category === 'turn'"><i class="fa fa-gamepad"></i> 게임</span>
          </td>
          <!-- 거래 방식 //-->
          <td>
            <!--
            method: game, category: money, type: send or receive => 사이트<->케이곤 머니이동
            method: cash, category: cash(파트너) or money(회원), type: send or receive => 회원간 캐쉬 이동(입/출금)
            method: game, category: turn, type: bet or win or draw or cancel => 게임 플레이
            //-->
            <span class="badge badge-pill badge-soft-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'bet'">
              <i class="fa fa-book" v-if="record.message"></i>
              배팅
            </span>
            <span class="badge badge-pill badge-soft-success" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'win'">
              <i class="fa fa-book" v-if="record.message"></i>
              승
            </span>
            <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'lose'">
              <i class="fa fa-book" v-if="record.message"></i>
              패
            </span>
            <span class="badge badge-pill badge-soft-info" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'draw'">
              <i class="fa fa-book" v-if="record.message"></i>
              무
            </span>
            <span class="badge badge-pill badge-soft-secondary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'turn' && record.type === 'cancel'">
              <i class="fa fa-book" v-if="record.message"></i>
              취소
            </span>
            <span class="badge badge-pill badge-soft-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'receive'">
              <i class="fa fa-plus" v-if="record.message"></i>
              입금
            </span>
            <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'cash' && record.type === 'send'">
              <i class="fa fa-minus" v-if="record.message"></i>
              출금
            </span>
            <span class="badge badge-pill badge-soft-primary" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'receive'">
              <i class="fa fa-plus" v-if="record.message"></i>
              입금
            </span>
            <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'send'">
              <i class="fa fa-minus" v-if="record.message"></i>
              출금
            </span>
            <span class="badge badge-pill" :class="(record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter')) ? 'badge-soft-danger':'badge-soft-primary'" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'enter'">
              <i class="fa fa-book" v-if="record.message"></i>
              게임입장
            </span>
            <span class="badge badge-pill badge-soft-danger" v-b-tooltip.hover data-placement="top" :title="record.message" v-if="record.category === 'money' && record.type === 'leave'">
              <i class="fa fa-book" v-if="record.message"></i>
              게임퇴장
            </span>
          </td>
          <!-- 수신인 //-->
          <td class="text-start">
            <span class="badge badge-pill badge-soft-pink" v-if="record.senderType === 'admin'">관리자</span>
            <span class="badge badge-pill badge-soft-primary" v-if="record.senderType === 'partner' && record.senderLevel === 'top'">본사</span>
            <span class="badge badge-pill badge-soft-success" v-if="record.senderType === 'partner' && record.senderLevel === 'super'">부본사</span>
            <span class="badge badge-pill badge-soft-info" v-if="record.senderType === 'partner' && record.senderLevel === 'master'">지사</span>
            <span class="badge badge-pill badge-soft-warning" v-if="record.senderType === 'partner' && record.senderLevel === 'agent'">총판</span>
            <span class="badge badge-pill badge-soft-dark" v-if="record.senderType === 'partner' && record.senderLevel === 'shop'">매장</span>
            <span class="badge badge-pill badge-soft-secondary" v-if="record.senderType === 'user'">회원</span>
            {{ record.sender ? record.senderUsername : '' }}
          </td>
          <!-- 게임사 //-->
          <td>{{ record.vendorName }}</td>
          <!-- 게임종류 //-->
          <td>
            <span class="badge bg-success p-1" v-if="record.gameCategoryKey === 'casino'">카지노</span>
            <span class="badge bg-warning p-1" v-if="record.gameCategoryKey === 'slot'">슬롯</span>
          </td>
          <!-- 게임형식 //-->
          <td>{{ record.gameTypeName.toUpperCase() }}</td>
          <!-- 게임명 //-->
          <td>
            <img :src="record.gameImage" style="width: 32px;" class="img-thumbnail" v-if="record.gameImage" />
            {{ record.gameName }}
          </td>
          <!-- 이전 보유알 //-->
          <td class="text-end">{{ record.beforeAmount.floatVal(2).formatThousands() }}</td>
          <!-- 금액 //-->
          <td class="text-end" :class="{'text-danger' : (record.type === 'send' || record.type === 'bet' || (record.user.type === 'partner' && record.type === 'enter'))}">{{ record.amount.floatVal(2).formatThousands() }}</td>
          <!-- 이후 보유알 //-->
          <td class="text-end">{{ record.afterAmount.floatVal(2).formatThousands() }}</td>
          <!-- 생성일 //-->
          <td class="small"><span v-b-tooltip.hover data-placement="top" :title="record.createdAt">{{ new Date(record.createdAt).format('YYYY-MM-DD HH:mm:ss.zzz') }}</span></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
