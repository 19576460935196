<script>
import Modify from './modify'
import '@vueup/vue-quill/dist/vue-quill.snow.css'
export default {
  components: {
    Modify
  },
  data() {
    return {
      document: null,
      openDialog: false,
    };
  },
  mounted() {

  },
  methods: {
    open: function(document) {
      this.document = document
      this.openDialog = true
    },
    openModify: function() {
      this.openDialog = false
      this.$refs['modal-document-modify'].open(this.document)
    },
    deleteDocument: async function() {
      if (!confirm('삭제하시겠습니까?')) {
        return
      }

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.board.delete(this.document._id)
        if (result.code !== 0) {
          return alert(result.msg)
        }
        this.$emit('searchList')

        this.openDialog = false
      } catch(e) {
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    searchList: function() {
      this.$emit('searchList')
    }
  }
};
</script>

<template>
  <div ref="contentWrapper" v-if="document">
    <b-modal
        :title="document.title"
        v-model="openDialog"
        size="xl"
        scrollable
        centered
        button-size="sm"
        hide-footer
    >
      <div class="d-block">
        <div style="min-height: 300px;" class="mb-3 ql-editor" v-html="document.content" v-if="document"></div>

        <div class="text-end">
          <hr />
          <button type='button' class='btn btn-primary me-1' v-if="$store.getters['auth/user'].type === 'admin'" @click='openModify'>{{ $t('수정') }}</button>
          <button type='button' class='btn btn-danger me-1' v-if="$store.getters['auth/user'].type === 'admin'" @click='deleteDocument'>{{ $t('삭제') }}</button>
          <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
        </div>
      </div>
    </b-modal>

    <Modify ref="modal-document-modify" @searchList="searchList" />
  </div>
</template>
